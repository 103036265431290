import { EnvironmentModel } from '@shared/models/environment.model';
import { FeatureFlag } from '@shared/models/feature-config.model';

class EnvironmentImpl implements EnvironmentModel {
  production = true;
  sentryDSN = 'https://a29d69920710411f9468febbcbaeb7a6@sentry.io/1471236';
  firebaseConfig = {
    apiKey: 'AIzaSyAjvh4PQK6vN-KGpPChJPbS1EAKNQ-z5cs',
    authDomain: 'dashlyprod.firebaseapp.com',
    databaseURL: 'https://dashlyprod.firebaseio.com',
    projectId: 'dashlyprod',
    storageBucket: 'dashlyprod.appspot.com',
    messagingSenderId: '145867078999',
    appId: '1:145867078999:web:0741889a3235fb06c3d212',
    measurementId: 'G-PNMLGBTEEG',
  };

  featureConfig = {
    [FeatureFlag.LANDCLAN_ADDRESSES]: false,
    [FeatureFlag.CREDIT_REPORT_CLIENT_TABS]: true,
    [FeatureFlag.CREDIT_REPORT_SETTINGS]: true,
    [FeatureFlag.PRE_COMPLETIONS]: true,
  };

  googleCloudPlatformApiKey = 'AIzaSyD9H4bXqHnCUOfGCxwIGMjArzWB5W_r0ks';
  urls = {
    clientPortalUrl: 'https://my.dashly.com',
    adviserPortalUrl: 'https://port.dashly.com',
    universalLeadCaptureUrl: 'https://welcome.dashly.com',
    apiUrl: 'https://api.dashly.com',
    dashlyIQUrl: 'https://iq.dashly.com',
  };
}

export const environment = new EnvironmentImpl();
